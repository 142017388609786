<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("denyOrder.denyOrder") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <h2 class="bold-24 my-3">{{ $t("denyOrder.denyType") }}</h2>

          <label class="cstm-check dropdown-item">
            <span class="text-black mx-2 regular-14">{{ $t("denyOrder.denyTheOrder") }}</span>
            <input type="radio" v-model="denyType" :value="1" /><span
              class="checkmark mx-2"
            ></span>
          </label>
          <label class="cstm-check dropdown-item">
            <span class="text-black mx-2 regular-14">{{ $t("denyOrder.denyItems") }}</span>
            <input type="radio" v-model="denyType" :value="2" /><span
              class="checkmark mx-2"
            ></span>
          </label>
          <div v-if="denyType === 2">
            <h2 class="bold-24 my-3">{{ $t("denyOrder.denyItems") }}</h2>
            <div
              class="row custom-input whitebg-input"
              v-for="p in removeProducts"
              :key="p.id"
            >
              <div class="col-auto">
                <label class="form-label bold-12 text-gray">{{ $t("denyOrder.deny") }}</label>
                <label class="cstm-check mt-3 mb-4">
                  <input
                    type="checkbox"
                    :value="true"
                    v-model="p.deny"
                    :disabled="
                      p?.sent_out_quantity === 0 ||
                      p?.need_to_sent_quantity === 0
                    "
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <!--
              <div class="col-3">
                <label class="form-label bold-12 text-gray">Quantity</label>
                <input
                  type="number"
                  v-model="p.quantity"
                  class="form-control"
                  :max="p?.max_quantity"
                  :min="p?.min_quantity"
                />
              </div>-->
              <div class="col">
                <div class="mb-3">
                  <label class="form-label bold-12 text-gray">{{ $t("chart1.product") }}</label>
                  <v-select
                    :options="products"
                    v-model="p.id"
                    :filterable="false"
                    :placeholder="$t(`copyProducts.selectProduct`)"
                    :reduce="(c) => c.variant_id"
                    v-on:option:selected="
                      (e) => changeProducts(e, e.variant_id)
                    "
                    v-on:option:deselected="() => changeProducts(0, 0)"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 3">
                        {{ $t("copyProducts.noProductsFoundFor") }} <em>{{ search }}</em
                        >.
                      </template>
                      <span v-else>{{ $t("copyProducts.startTypingToSearchForAProduct") }}</span>
                    </template>
                    <template v-slot:selected-option="option">
                      <div class="row">
                        <div class="col-auto px-0">
                          <img
                            :src="option?.image"
                            v-if="
                              option?.image !== null &&
                              option?.image !== undefined
                            "
                            class="product-image"
                            alt=""
                          />
                          <BaseIcon
                            v-else
                            name="no-image"
                            class="product-image"
                          />
                        </div>
                        <div class="col">
                          <div>
                            <b>{{ option.name }}</b>
                          </div>
                          <div>
                            <span
                              v-for="char in option.caracteristics"
                              :key="char"
                              class="me-2"
                            >
                              {{ char }}
                            </span>
                            <span class="me-2">
                              {{ option.serial }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:option="option">
                       <span class="me-2">
                        {{option.serial}}
                      </span>
                      <span
                        v-for="char in option.caracteristics"
                        :key="char"
                        class="me-2"
                      >
                        {{ char }}
                      </span>
                      <b>{{ option.name }}</b>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="denyOrder"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("denyOrder.deny") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import BaseIcon from "../icons/BaseIcon";
import http from "@/modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "DenyOrder",
  props: {
    modelValue: Number,
    products: Object,
  },
  emits: ["update:modelValue", "newItem"],
  data() {
    return {
      store: useStore(),
      hasReturn: false,
      myModal: undefined,
      success: false,
      loadAddBtn: false,
      denyType: 1,
      removeProducts: [{ id: null, quantity: 1, deny: true }],
    };
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      this.loadAddress();
    },
    denyOrder() {
      this.loadAddBtn = true;
      let deny = {
        order_id: this.modelValue,
        deny_type: this.denyType,
      };
      deny.items = [];

      if (this.denyType === 2) {
        for (const product of this.removeProducts) {
          if (product.id !== null && product.id !== undefined && product.deny) {
            deny.items.unshift({
              id: product.id,
              //quantity: product.quantity,
            });
          }
        }
      }

      http
        .fetch("/orders/deny", deny, true)
        .then((data) => {
          this.$emit("newItem", data);
          this.myModal.hide();
          this.$emit("update:show", false);
          this.loadAddBtn = false;
          this.removeProducts = [{ id: null, quantity: 1 }];
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    changeProducts(item, variant_id) {
      let hasNull = false;
      let quantity = 0;
      let count = 0;
      let forData = this.removeProducts;
      let row = null;

      for (const p in forData) {
        if (forData[p].id === null) {
          if (p !== 0) {
            forData.splice(p, 1);
          } else {
            hasNull = true;
          }
        } else if (forData[p].id === variant_id) {
          if (row === null) {
            row = forData[p];
          }
          count++;
          quantity += forData[p].quantity;
          if (count > 1) {
            forData.splice(p, 1);
          }
        }
      }
      if (count === 0) {
        return;
      }

      if (count > 1) {
        row.quantity = quantity;
      }
      row.max_quantity = item?.need_to_sent_quantity;
      row.min_quantity = item?.quantity - item?.sent_out_quantity;
      if (row.quantity > row.max_quantity) {
        row.quantity = row.max_quantity;
      }
      if (row.quantity < row.min_quantity) {
        row.quantity = row.min_quantity;
      }

      if (!hasNull) {
        forData.unshift({
          id: null,
          quantity: 1,
          deny: true,
        });
      }
    },
  },
  components: { BaseIcon },
};
</script>
